.App {
  text-align: center;
}

$index: 1;
$reveal-duration: 0.15s;
$reveal-delay-increment: 0.15s;

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-image: url("/background3.jpg");
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:hover {
  text-decoration: none;
}

a {
  position: relative;
}

.link__effect:after {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  transition: all 0.35s ease;

  background-color: white;
  transition: all 0.35s ease-in-out;
}

.link__effect:hover:after {
  width: 100%;
}

.link__effect {
  position: relative;

  filter: opacity(0.75);
  transition: all 0.35s ease-in-out;
}
.link__effect:hover {
  filter: opacity(1);
  transition: all 0.35s ease-in-out;
}

.description__about a {
  position: relative;
  font-size: 2rem;
  font-family: Helvetica Neue;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
}

.description__about a:hover {
  text-decoration: none;
  color: white;
}

.description__about a:after {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  transition: all 1s ease;

  background-color: white;
}
.description__about a:hover:after {
  width: 100%;
}

.description__about p {
  color: #fff;
  display: block;
  position: relative;
  font-size: 0.8rem;
  font-family: Helvetica Neue;
  font-weight: 300;
  line-height: 1;
}

// .active-hover {
//   opacity: 1; /* Par exemple, reste entièrement visible */
//   transition: all ease 0.5s;
// }
// .active-hover ~ .item .title__project {
//   opacity: 0;
//   transition: all ease 0.5s;
// }
/* Lorsque un élément a la classe active-hover, appliquez un style aux autres éléments */
// .active-hover ~ .item img,
// .active-hover ~ .item video {
//   opacity: 0.5; /* Réduit l'opacité des images et vidéos des autres éléments */
//   transition: all ease 0.5s;
//   transform: scale(0.7);
//   background-color: black;
// }

.item__asset {
  height: auto !important;
  border-radius: 0.2rem;
  line-height: 0;
  transition: all ease 1s;
}
.item__asset img,
.item__asset video {
  border-radius: 0.2rem;
  background-color: black;
}
.item {
  border-radius: 0.2rem;
  line-height: 1;
}

.item__asset video {
  object-fit: cover;
    transition: all ease 2s;
}
.active-hover-project .item .title__project {
  opacity: 0;
  transition: all ease 2s;
}
.active-hover-project .item .item__asset {
  background-color: black;
  transform: scale(0.85);
  filter: brightness(0.8);
  transition: all ease 2s;
  
 
}
.active-hover-project .item .item__asset img,
.active-hover-project .item .item__asset video {
  z-index: 2;

  background-color: black;
}

.active-hover-project .active-hover {
  z-index: 99999;
  position: relative;
}
.active-hover-project .item {
  transition: all ease 2s;
  position: relative;
}

.title__project {
  font-size: 0.8vw;
}

@media screen and (max-width: 1024px) {
  .title__project {
    font-size: 1.5vh;
  }
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  backdrop-filter: blur(10px);
  background-size: cover;
  background-color: #000;
  background-image: url("background3.jpg") !important;
  background-repeat: no-repeat;
  background-position: center;
}

.item {
  &.intro__item {
    position: fixed;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    display: none;
    width: calc(50vw);

    .item__frame {
      width: 50vw !important;
      transition: all ease 0.5s;
      transform: scale(1) !important;
      transform-origin: center;
      animation: reveal 2s 2s forwards, hide 3s 6s forwards !important;
    }
  }
  @for $index from 1 through 30 {
    &:nth-child(#{$index}) {
      .item__frame.showscale {
        animation-delay: 3 + $reveal-delay-increment * ($index - 1);
        overflow: inherit !important;
      }
    }
  }

  .item__frame {
    &.final-class {
      // visibility: visible !important;
      // animation: inherit !important; 
      animation: floating 10s infinite;
      transition: all ease 5s;
      transform: translateY(-2.5%);
    }
    &.showscale{
      visibility: hidden;
      animation: showscale 1s forwards;
    }
   
    transform: scale(0.9);
    transition: all ease 5s;
    transform-origin: center;
  }
}

.intro__block {
  // animation: 2s ease-in-out 10s 1 normal forwards running scalescroll;
  position: fixed;
  height: 100vh;
  width: 100%;

  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 999;
  animation: removeall 2s 7s forwards;
  .intro__video {
    @media screen and (max-width: 1024px) {
      width: 90% !important;
    }
    width: 33.3334vw !important;
    transition: all ease 0.5s;
    clip-path: inset(100% 0);
    transform: scale(1) !important;
    transform-origin: center;
    animation: reveal 2s 2s forwards, hide 3s 6s forwards !important;
  }
}

@keyframes showscale {
  0% {
    visibility: visible;
    transform: scale(0.9);

    overflow: inherit;
  }
  100% {
    visibility: visible;
    transform: scale(1);
    clip-path: inherit !important;
    overflow: inherit;
  }
}

@keyframes reveal {
  0% {
    clip-path: inset(100% 0);
    overflow: hidden;
  }
  100% {
    clip-path: inset(0 0 0 0);
    overflow: inherit;
  }
}

@keyframes removeall {
  0% {
    clip-path: inset(0 0 0 0);
    overflow: inherit;
  }
  100% {
    clip-path: inset(100% 0%);
    overflow: hidden;
    display: none;
  }
}
@keyframes hide {
  0% {
    clip-path: inset(0 0 0 0);
    overflow: inherit;
  }
  100% {
    clip-path: inset(100% 0%);
    overflow: hidden;
    display: none;
  }
}

@keyframes scalescroll {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes floating {
  0%{transform: translateY(-2.5%);transition: all ease 5s;}
  50%{transform: translateY(2.5%);transition: all ease 5s;}
  100%{transform: translateY(-2.5%);transition: all ease 5s;}
}

.canvas-scroll {
  transform: scale(0.75);
  animation-delay: 5s;
  transform-origin: center center;
  animation: scalescroll 1s ease-in-out forwards;
}

.item__about {
  a {
    @media screen and (max-width: 1024px) {
      font-size: 2.5vh;
    }
    font-size: 1.5vw;
    color: white;
  }
  p {
    @media screen and (max-width: 1024px) {
      font-size: 1.5vh;
    }
    font-size: 1vw;
    color: white;
  }
}

.info__project {
  @media screen and (max-width: 1024px) {
    left: 3vh;
    bottom: 5vh;
  }
  color: #fff;
  pointer-events: none;
  z-index: 10;
  position: absolute;
  left: 2vw;
  bottom: 3vw;
  .title {
    font-size: 1.5vw;
    line-height: 1.2;
    font-family: "Helvetica Neue";
    font-weight: 600;
    text-transform: "uppercase";
    color: "#fff";
    @media screen and (max-width: 1024px) {
      font-size: 2vh;
    }
  }
  .caption {
    font-size: 1vw;
    line-height: 1.2;
    font-family: "Helvetica Neue";

    color: #fff;
    @media screen and (max-width: 1024px) {
      font-size: 1.5vh;
    }
  }
}


